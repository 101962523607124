import { PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import moment from "moment";
import { createSlice } from "utils/@reduxjs/toolkit";
import { TreatmentBankState } from "../types";
import { getTreatmentsRenderTextByType } from "./treatment.helper";
const initialState = TreatmentBankState;

function formatDate(date: string) {
  const dateString = moment(date).format("DD/MM/YYYY");
  return dateString;
}

export const TreatmentBankSlice = createSlice({
  name: "treatmentBankState",
  initialState,
  reducers: {
    setQuery: (state, action) => {
      set(state.query, action.payload.name, action.payload.value);
    },
    setEditedItems: (
      state,
      action: PayloadAction<{ index: number; key: string; value: any }>
    ) => {
      const { index, key, value } = action.payload;
      const itemindex = state.list.editItems[index].optionalInstructor.indexOf(
        parseInt(value)
      );
      if (itemindex !== -1) {
        state.list.editItems[index].optionalInstructor.splice(itemindex, 1);
      } else {
        state.list.editItems[index].optionalInstructor.push(parseInt(value));
      }
    },
    setEditedCheckboxItems: (
      state,
      action: PayloadAction<{ index: number; value: any }>
    ) => {
      const { index, value } = action.payload;

      state.list.editItems[index].isMust = value;
    },
    setTreatmentBankTreatmentItems: (state, action) => {
      const listItemsToSet: any[] = [];
      for (const item of action.payload.items) {
        listItemsToSet.push({
          id: item.id,
          treatmentId: item.treatmentType.id,
          treatmentTypeName: item.treatmentType.name,
          groupIndividual: item.treatmentType.type,
          numberOfSession: item.treatmentType.session_count,
          description: item.treatmentType.description,
          isMust: item.isMust,
          maximumPerPatient: item.maximumPerPatient,
          optionalInstructor: [],
          startDate: moment(item.startDate).format("DD-MM-YYYY"),
          endDate: moment(item.endDate).format("DD-MM-YYYY"),
        });
      }
      state.list.editItems = listItemsToSet;
      return state;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      state.query.page = state.list.meta.currentPage + 1;
      state.loading = true;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },

    getTreatmentBankList: (state) => {
      state.query.page = 1;
      state.loading = true;
    },
    getTreatmentBankListSuccess: (
      state,
      action: PayloadAction<{ items: []; links: any; meta: any }>
    ) => {
      try {
        state.loading = false;
        const items = action.payload.items.map((item: any) => {
          return {
            ...item,
            indTreatmentType: getTreatmentsRenderTextByType(
              item["treatments"],
              "individual"
            ),
            grpTreatmentType: getTreatmentsRenderTextByType(
              item["treatments"],
              "group"
            ),
          };
        });
        state.list.items =
          action.payload.meta.currentPage === 1
            ? items
            : [...state.list.items, ...items];
        state.list.meta = action.payload.meta;
        state.list.links = action.payload.links;
      } catch (e) {
        console.log(e);
      }
    },

    getTreatmentBankTreatmentList: (state, action) => {
      state.loading = true;
    },
    getTreatmentBankById: (state, action) => {
      state.loading = true;
    },

    addTreatmentBankRow: (state, action) => {
      state.loading = true;
    },

    createTreatmentBankTreatment: (state, action) => {
      state.loading = true;
    },
    setTreatmentBankById: (state, action) => {
      state.loading = false;
      state.from.id = action.payload.id;
      state.from.organizationId = action.payload.organizationId;
      state.from.departmentId = action.payload.departmentId;
      state.from.bankName = action.payload.name;
      state.from.bankStatus = action.payload.bankStatus;
      state.from.serverBankStatus = action.payload.bankStatus;
      state.from.assignedDate = action.payload.assignedDate;
      state.from.totalPatient = action.payload.totalPatientCapacity;
      state.from.indPerPatient = action.payload.individualPerPatient;
      state.from.groupPerPatient = action.payload.groupPerPatient;
      state.from.treatmentBankAsset = action.payload.treatmentBankAsset;
      state.from.associatedPatientCount = action.payload.associatedPatientCount;
      state.from.associatedTimeSlotCount =
        action.payload.associatedTimeSlotCount;
      state.loading = false;

      for (let index = 0; index < action.payload.treatments.length; index++) {
        const element = action.payload.treatments[index];
        state.list.editItems.push({
          idx: 0,
          id: element.id,
          treatmentId: element.treatmentId,
          treatmentTypeName: element.treatment.name,
          groupIndividual: element.treatment.type,
          numberOfSession: element.treatment.session_count,
          description: element.treatment.description,
          isMust: element.isMust,
          maximumPerPatient: element.maximumPerPatient,
          optionalInstructor: element.optionalInstructor.map(
            (x) => x.instructorId
          ),
          allOptionalInstructor: element.optionalInstructor,
          startDate: element.startDate,
          endDate: element.endDate,
          instructorList: element.treatment.userAssignedTreatment.map((x) => {
            return {
              id: x.user.id,
              firstName: x.user.firstName,
              lastName: x.user.lastName,
              name: x.user.firstName + " " + x.user.lastName,
            };
          }),
          action: "edit",
        });
      }
    },
    setTreatmentBankTreatment: (state, action) => {
      const {
        treatmentName,
        instructorName,
        startDate,
        endDate,
        max,
        treatmentId,
        isMust,
        instructorList,
        type,
      } = action.payload;
      state.list.editItems.push({
        idx: state.list.editItems.length,
        id: null,
        treatmentId,
        treatmentTypeName: treatmentName,
        groupIndividual: type,
        numberOfSession: action.payload.treatment.session_count,
        description: action.payload.treatment.description,
        isMust: isMust,
        maximumPerPatient: max,
        optionalInstructor: instructorName,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        instructorList: instructorList,
        action: "add",
      });
      state.treatmentBankDetailsEditDetails.open = false;
    },
    deleteTreatmentBankTreatment: (state, action) => {
      const { id, idx } = action.payload;
      if (id) {
        for (let i = 0; i < state.list.editItems.length; i++) {
          const item = state.list.editItems[i];
          if (item.id === id) {
            state.list.editItems[i].action = "delete";
            break;
          }
        }
      } else {
        state.list.editItems = state.list.editItems.filter(
          (item, index) => index !== idx
        );
      }
    },
    saveTreatmentBankWithTreatments: (
      state,
      action: PayloadAction<{
        treatmentBankDetails: any;
        treatmentBankId: number | null;
        callback: any;
      }>
    ) => {
      state.loading = true;
    },
    saveTreatmentBankWithTreatmentsFailure: (state) => {},
    updateTreatmentBankRow: (state, action) => {
      state.loading = true;
      state.error = "";
    },

    deleteTreatmentBankRow: (
      state,
      action: PayloadAction<{ id: string; callback?: () => void }>
    ) => {
      state.loading = true;
      state.error = "";
    },
    deleteTreatmentBankRowComplete: (state) => {
      state.loading = false;
    },
    getOrganizationList: (state) => {
      state.loading = true;
    },
    getOrganizationListSuccess: (state, action) => {
      state.loading = false;
      state.organizations = action.payload.map((item) => {
        return {
          id: item.id,
          name: item.name,
          display: item.name,
        };
      });
    },
    setTreatmentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getDepartmentList: (state, action) => {
      state.loading = true;
    },
    getDepartmentListSuccess: (state, action) => {
      state.loading = false;
      state.departments = action.payload.map((item) => {
        return {
          id: item.id,
          name: item.departmentName,
          display: item.departmentName,
        };
      });
    },
    setDepartmentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    setOrganizationOpen: (state, action) => {
      state.organizationOpen = action.payload;
    },
    setDepartmentOpen: (state, action) => {
      state.departmentOpen = action.payload;
    },
    setOraganization: (state, action) => {
      state.organization = action.payload;
    },
    setDepartment: (state, action) => {
      state.department = action.payload;
    },
    setEditTreatmentBankItemId: (state, action) => {
      state.list.editItemId = action.payload.id;
    },
    resetForm: (state) => {
      state.from = initialState.from;
      state.list.items = [];
      state.list.editItems = [];
    },
    resetTreatmentBankDetailsEditedData: (state) => {
      state.treatmentBankDetailsEditDetails = {
        ...initialState.treatmentBankDetailsEditDetails,
      };
    },
    resetEditTreatmentRowDetails: (state) => {
      state.list.editItemRowDetails = {};
    },
    resettreatmentBankDetailsEditDetails: (state) => {
      state.loading = false;
      state.treatmentBankDetailsEditDetails.deleteBankName = "";
      state.treatmentBankDetailsEditDetails.treatmentName = "";
      state.treatmentBankDetailsEditDetails.treatmentId = 0;
      state.treatmentBankDetailsEditDetails.instructorName = [];
      state.treatmentBankDetailsEditDetails.endDate = null;
      state.treatmentBankDetailsEditDetails.startDate = null;
      state.treatmentBankDetailsEditDetails.max = "";
      state.treatmentBankDetailsEditDetails.isMust = false;
    },
    setTreatmentBankDetailsEditedData: (state, action) => {
      const { key, value } = action.payload;
      state.treatmentBankDetailsEditDetails[key] = value;
    },
    getTreatmentTypeOptions: (state) => {},
    getOptionInstructorOptions: (state, action: PayloadAction<number>) => {},
    setTreatmentTypeOptions: (state, action) => {
      state.treatmentTypes = action.payload.items.map((item) => {
        return {
          id: item.id,
          name: item.name,
          value: item.name,
          type: item.type,
        };
      });
    },
    setOptiionInstructorOptions: (state, action) => {
      state.optionalTypes = action.payload.items.map((item) => {
        return {
          id: item.id,
          name: item.firstName + " " + item.lastName,
          value: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
        };
      });
    },
    resetEditTreatmentBankTreatments: (state) => {
      state.list.editItems = [];
      state.list.editItemId = null;
    },
    toggleTreatmentBankEditDetailsMode: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isTreatmentBankDetailsInViewMode = action.payload;
      if (state.isTreatmentBankDetailsInViewMode) {
      }
    },
    deleteBankTreatment: (state, action) => {
      // permanent delete from DB
    },
    saveNewBankTreatment: (state, action) => {
      //
    },
    setEditTreatmentBankRowDetails: (state, action) => {
      const { key, value } = action.payload;
      set(state.from, key, value);
    },
    setResetTreatmentBank: (state) => {
      state.from = initialState.from;
      state.list = initialState.list;
    },
    resetModule: (state) => {
      state.query.departmentId = initialState.query.departmentId;
      state.query.organizationId = initialState.query.organizationId;
    },
    setEditTreatmentBankRowDetailsEditMode: (state, action) => {
      const { key, value } = action.payload;
      state.list.editItemRowDetails = action.payload;
    },
    setFilledTreatmentBankDetails: (state, action) => {
      const { id } = action.payload;

      if (id) {
        const treatment = state.list.items.filter((item) => item.id === id)[0];

        state.list.editItemRowDetails = {
          ...treatment,
        };
      }
    },
    resetQueryForListing: (state) => {
      state.query = {
        ...initialState.query,
      };
    },
    uploadImage: (state, action) => {
      state.fileUploading = true;
    },
    setUploadImageSuccess: (state, action) => {
      state.from.treatmentBankAsset = {
        resourceName: action.payload.fileName,
        resourcePath: action.payload.fileNameWithPrefix,
        resourceSize: action.payload.fileSize,
        resourceType: action.payload.fileType,
      };
    },
    resetFileUploaded: (state) => {
      state.from.treatmentBankAsset = null;
    },
  },
});
export const { reducer, actions, name: sliceKey } = TreatmentBankSlice;
