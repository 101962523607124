import { Box, Card, Grid, Modal, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";

import { PrimaryButton } from "app/components/Button/PrimaryButton";
import TableLoader from "app/components/DataTable/components/TableLoader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEdit,
  selectForm,
  selectQuestionList,
  selectQuestionnaireResultId,
} from "../../redux/selector";
import { actions } from "../../redux/slice";
import { QuestionerAnsweredQuestion } from "../../types";
import { useStyles } from "./styles";

const OpenQuestionerModal = () => {
  const styles = useStyles();
  const viewData: any = useSelector(selectEdit);
  const questionList = useSelector(selectQuestionList);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const questionnaireResultId = useSelector(selectQuestionnaireResultId);
  const handleClose = () => {
    dispatch(actions.toggalModal({ id: undefined }));
  };

  useEffect(() => {
    if (Number(questionnaireResultId) > 0) {
      dispatch(actions.getById({ id: questionnaireResultId }));
    }
  }, [questionnaireResultId]);
  const isOpen = Number(questionnaireResultId) > 0;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card className={styles.modal}>
        <div className={styles.modalContainer}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Typography
              component={"span"}
              align="center"
              className={styles.headerModal}
            >
              {viewData?.type === "open"
                ? t("QUESTIONNAIRES.OPEN_QUESTIONNAIRES")
                : t("QUESTIONNAIRES.MULTI_CHOICE_QUESTIONNAIRES")}
            </Typography>
          </Box>
          <Grid xs={6.2} sx={{ paddingBottom: "10px" }}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.trHead}>
                  <td className={styles.tdHeader}>
                    {t("QUESTIONNAIRES.DATE_SENT")}
                  </td>
                  <td className={styles.tdHeader}>
                    {t("QUESTIONNAIRES.DATE_ANSWERED")}
                  </td>
                  <td className={styles.tdHeader}>
                    {t("QUESTIONNAIRES.PATIENT_NAME")}
                  </td>
                  <td className={styles.tdHeader}>
                    {t("QUESTIONNAIRES.QUESTIONNAIRE_NAME")}
                  </td>
                  <td className={styles.tdHeader}>
                    {t("QUESTIONNAIRES.NUMBER_OF_QUESTIONS")}
                  </td>
                  {viewData?.questionnaireType !== "Open Questionnaire" && (
                    <td className={styles.tdHeader}>
                      {t("QUESTIONNAIRES.TOTAL_GRADE")}
                    </td>
                  )}
                </tr>
              </thead>
              <tbody>
                {form?.isLoading && (
                  <tr className={styles.tr}>
                    <td colSpan={6}>
                      <TableLoader />
                    </td>
                  </tr>
                )}
                <tr className={styles.tr}>
                  <td className={styles.actionTD}>
                    {moment(form?.createdAt).format("DD/MM/YYYY")}
                  </td>
                  <td className={styles.dateTd}>
                    {moment(form?.updatedAt).format("DD/MM/YYYY")}
                  </td>
                  <td className={styles.dateTd}>{form?.patient.firstName}</td>
                  <td className={styles.dateTd}>{form?.questionnaire.name}</td>
                  <td className={styles.dateTd}>
                    {" "}
                    {form?.questionnaire.totalQuestions}
                  </td>
                  {form.questionnaire.type !== "" && (
                    <td className={styles.dateTd}>{form?.score}</td>
                  )}
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid className={styles.tableWrap}>
            <table className={styles.table}>
              <thead>
                <tr className={styles.trHead}>
                  <td className={styles.tdHeader}>
                    {t("QUESTIONNAIRES.QUESTION_NUMBER")}
                  </td>
                  <td className={styles.tdHeader}>
                    {t("QUESTIONNAIRES.QUESTION_BODY")}
                  </td>
                  {viewData?.type === "open" && (
                    <td className={styles.tdHeader}>
                      {t("QUESTIONNAIRES.ANSWER")}
                    </td>
                  )}
                  {!(viewData?.type === "open") && (
                    <td className={styles.tdHeader}>
                      {t("QUESTIONNAIRES.PATIENT_SELECTION")}
                    </td>
                  )}
                  {!(viewData?.type === "open") && (
                    <td className={styles.tdHeader}>
                      {t("QUESTIONNAIRES.PATIENT_SELECTION_WEIGHT")}
                    </td>
                  )}
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                {form?.isLoading && (
                  <tr className={styles.tr}>
                    <td colSpan={6}>
                      <TableLoader />
                    </td>
                  </tr>
                )}
                {form.questionerAnsweredQuestions.map(
                  (item: QuestionerAnsweredQuestion, index) => {
                    return (
                      <tr key={index} className={styles.tr}>
                        <td className={styles.actionTD}>{index + 1}</td>
                        <td className={styles.dateTd}>
                          {item.originalQuestion.question}
                        </td>
                        {viewData?.type === "open" && (
                          <td className={styles.dateTd}>{item?.answer}</td>
                        )}
                        {!(viewData?.type === "open") && (
                          <td className={styles.dateTd}>
                            {item?.originalAnswer?.content}
                          </td>
                        )}
                        {!(viewData?.type === "open") && (
                          <td className={styles.dateTd}>
                            {item?.originalAnswer?.weight}
                          </td>
                        )}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </Grid>
          <div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "20px",
              }}
            >
              <PrimaryButton
                isLoading={form?.isLoading}
                label={t("BUTTONS.CANCEL")}
                onClick={handleClose}
                size="small"
                variant="outlined"
              />
            </Box>
          </div>
        </div>
      </Card>
    </Modal>
  );
};
export default OpenQuestionerModal;
