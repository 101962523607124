import { Grid } from "@mui/material";
import OrganizationField from "app/components/FieldComponent/Organization";
import TreatmentTypeFiled from "app/components/FieldComponent/TreatmentType";
import UserGroupsFiled from "app/components/FieldComponent/UserGroups";
import { selectQuery } from "app/containers/Users/redux/selector";
import { actions } from "app/containers/Users/redux/slice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

type Props = {
  isResetRequired: boolean;
};

const Filters = ({ isResetRequired }: Props) => {
  const dispatch = useDispatch();

  const { organizationId, userGroupId, treatmentId } = useSelector(selectQuery);

  const setPageQuery = (name: string, value: any) => {
    dispatch(actions.setQuery({ name, value }));
  };

  useEffect(() => {
    if (isResetRequired) {
      dispatch(actions.resetModule());
    }
  }, [dispatch, isResetRequired]);

  return (
    <Grid sx={{ padding: "60px 0px 0px 0px" }}>
      <Grid container spacing={2} md={12} sx={{ paddingBottom: "10px" }}>
        <OrganizationField
          value={organizationId}
          changeValue={(value) => {
            setPageQuery("organizationId", value);
          }}
          showAll={true}
          activeRequired={false}
        ></OrganizationField>

        <TreatmentTypeFiled
          bankId={0}
          showAll={true}
          value={treatmentId}
          changeValue={(value) => setPageQuery("treatmentId", value)}
          fetchAll={false}
        />
        <UserGroupsFiled
          showAll={true}
          value={userGroupId}
          changeValue={(value) => setPageQuery("userGroupId", value)}
        />
      </Grid>
    </Grid>
  );
};
export default Filters;
