import React, { useEffect, useState } from "react";

import { CalendarMonth, KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Modal } from "app/components/Modal";
import {
  selectOptionalInstructorOptions,
  selectTreatmentBankDetailsEdited,
  selectTreatmentTypesOptions,
} from "app/containers/TreatmentBanks/redux/selector";
import { actions } from "app/containers/TreatmentBanks/redux/slice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectList } from "../../../../redux/selector";
import { useStyles } from "./styles";
import { ErrorsType, onValidate } from "./validator";

export const AddTreatmentBank = () => {
  const dispatch = useDispatch();
  const treatmentTypes = useSelector(selectTreatmentTypesOptions);
  const optionalInstructiorTypes = useSelector(selectOptionalInstructorOptions);
  const listitems = useSelector(selectList);
  const [errors, setErrors] = useState<ErrorsType>({
    treatmentId: "",
    startDate: "",
    endDate: "",
    max: "",
    instructorName: "",
  });

  const {
    modalName,
    open,
    startDate,
    endDate,
    treatmentId,
    treatmentName,
    instructorName,
    max,
    isMust,
  } = useSelector(selectTreatmentBankDetailsEdited);
  const styles = useStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!open) {
      setErrors({
        treatmentId: "",
        startDate: "",
        endDate: "",
        max: "",
        instructorName: "",
      });
    }
  }, [open]);

  const handleSave = () => {
    const errors = onValidate({
      treatmentId,
      startDate: startDate ?? "",
      endDate: endDate ?? "",
      max: parseInt(max ?? "0"),
      instructorName: instructorName,
    });
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const isExist = listitems?.editItems?.filter((item) => {
      return Number(item.treatmentId) === Number(treatmentId);
    });
    if (isExist?.length > 0) {
      setErrors({
        ...errors,
        treatmentId: "TREATMENT_BANKS.TREATMENT_ALREADY_EXIST",
      });
      return;
    }
    dispatch(
      actions.createTreatmentBankTreatment({
        treatmentId,
        instructorName,
        startDate,
        endDate,
        max,
        treatmentName,
        isMust,
        instructorList: optionalInstructiorTypes,
        type: treatmentTypes.filter((x) => x.id == treatmentId)[0].type,
      })
    );
  };

  const handleClose = () => {
    dispatch(actions.resetTreatmentBankDetailsEditedData());
  };

  const direction = useTheme().direction;

  const onChangeHandler = ({ key, value }) => {
    setErrors({
      ...errors,
      [key]: "",
    });
    dispatch(
      actions.setTreatmentBankDetailsEditedData({
        key,
        value,
      })
    );
    if (key === "treatmentId") {
      dispatch(
        actions.setTreatmentBankDetailsEditedData({
          key: "instructorName",
          value: [],
        })
      );
      dispatch(actions.getOptionInstructorOptions(value));
      if (parseInt(value) > 0 && treatmentTypes.length > 0) {
        dispatch(
          actions.setTreatmentBankDetailsEditedData({
            key: "treatmentName",
            value: treatmentTypes.filter((x) => x.id == value)[0].name,
          })
        );
      }
    }
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Card
        className={styles.messageModal}
        sx={{
          direction,
        }}
      >
        <div className={styles.modalContainer}>
          <div>
            <h1 className={styles.treatment_heading}>
              {t(`TREATMENT_BANKS.${modalName}`)}{" "}
            </h1>
          </div>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={5.5}>
              <label className={styles.label}>
                {t("TREATMENT_BANKS.TREATMENT_TYPE_NAME")}
              </label>
              <Select
                margin="dense"
                id="organizationName"
                name="organizationName"
                fullWidth
                variant="outlined"
                required
                autoComplete={"off"}
                value={treatmentId}
                className={styles.textEnabled}
                onChange={(e) =>
                  onChangeHandler({
                    key: "treatmentId",
                    value: e.target.value,
                  })
                }
                IconComponent={KeyboardArrowDown}
                displayEmpty
                error={!!errors?.treatmentId}
              >
                {treatmentTypes.map((treatmentType) => (
                  <MenuItem value={treatmentType.id}>
                    {treatmentType.name}
                  </MenuItem>
                ))}
              </Select>
              {errors?.treatmentId && (
                <span className={styles.error}>{t(errors?.treatmentId)}</span>
              )}
            </Grid>
            <Grid item xs={5.5}>
              <label className={styles.label}>
                {t("TREATMENT_BANKS.OPTIONAL_INSTRUCTORS")} (
                {t("TREATMENT_BANKS.MULTI_SELECT")})
              </label>
              <Select
                variant="outlined"
                fullWidth
                margin="dense"
                required
                autoComplete={"off"}
                value={instructorName}
                onChange={(e) =>
                  onChangeHandler({
                    key: "instructorName",
                    value: e.target.value,
                  })
                }
                className={styles.textEnabled}
                multiple
                IconComponent={KeyboardArrowDown}
                displayEmpty
                error={!!errors?.instructorName}
              >
                {optionalInstructiorTypes.map((treatmentType) => (
                  <MenuItem value={treatmentType.id}>
                    {treatmentType.name}
                  </MenuItem>
                ))}
              </Select>
              {errors?.instructorName && (
                <span className={styles.error}>
                  {t(errors?.instructorName)}
                </span>
              )}
            </Grid>
          </Grid>
          <Grid style={{ marginTop: "5px" }} container spacing={2}>
            <Grid item xs={3.5}>
              <label className={styles.label}>
                {t("TREATMENT_BANKS.START_DATE")}
              </label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className={styles.dateArea}
                  views={["day", "month", "year"]}
                  value={startDate}
                  minDate={new Date()}
                  onChange={(newValue) => {
                    onChangeHandler({
                      key: "startDate",
                      value: newValue,
                    });
                  }}
                  components={{
                    OpenPickerIcon: CalendarMonth,
                  }}
                  OpenPickerButtonProps={{
                    style: { color: "#012397" },
                  }}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      margin="dense"
                      helperText={null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "__/__/____",
                      }}
                      error={!!errors?.startDate}
                    />
                  )}
                />
              </LocalizationProvider>
              {errors?.startDate && (
                <span className={styles.error}>{t(errors?.startDate)}</span>
              )}
            </Grid>
            <Grid item xs={3.5}>
              <label className={styles.label}>
                {t("TREATMENT_BANKS.END_DATE")}
              </label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className={styles.dateArea}
                  views={["day", "month", "year"]}
                  value={endDate}
                  minDate={new Date()}
                  onChange={(newValue) => {
                    onChangeHandler({
                      key: "endDate",
                      value: newValue,
                    });
                  }}
                  OpenPickerButtonProps={{
                    style: { color: "#012397" },
                  }}
                  components={{
                    OpenPickerIcon: CalendarMonth,
                  }}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      margin="dense"
                      helperText={null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "__/__/____",
                      }}
                      error={!!errors?.endDate}
                    />
                  )}
                />
              </LocalizationProvider>
              {errors?.endDate && (
                <span className={styles.error}>{t(errors?.endDate)}</span>
              )}
            </Grid>
            <Grid item xs={5}>
              <label className={styles.label}>
                {t("TREATMENT_BANKS.MAXIMUM_PER_PATIENT")}
              </label>
              <TextField
                margin="dense"
                fullWidth
                variant="outlined"
                required
                InputProps={{ inputProps: { min: 1, max: 100 } }}
                type="number"
                value={max}
                onChange={(e) => {
                  onChangeHandler({
                    key: "max",
                    value: e.target.value,
                  });
                }}
                autoComplete={"off"}
                className={styles.dateArea}
                error={!!errors?.max}
                helperText={errors?.max && t(errors?.max)}
                FormHelperTextProps={{
                  style: {
                    textAlign: i18n.dir() === "ltr" ? "left" : "right",
                  },
                }}
                placeholder={t("TREATMENT_BANKS.ENTER_MAXIMUM_PER_PATIENT")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3.5}>
              <FormControlLabel
                style={{ margin: "10px 0px -10px -10px" }}
                control={<Checkbox color="info" />}
                label={t("TREATMENT_BANKS.IS_MUST")}
                value={isMust}
                onChange={(e) => {
                  onChangeHandler({
                    key: "isMust",
                    value: !isMust,
                  });
                }}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              gap: "10px",
            }}
          >
            <Button
              className={styles.cancelBtn}
              variant="outlined"
              onClick={handleClose}
            >
              {t("BUTTONS.CANCEL")}
            </Button>
            <Button
              className={styles.saveBtn}
              variant="contained"
              onClick={() => handleSave()}
              sx={{
                "&.Mui-disabled": {
                  color: "#ffffff",
                },
              }}
            >
              {t("BUTTONS.ADD")}
            </Button>
          </Box>
        </div>
      </Card>
    </Modal>
  );
};
